input[type="radio"] {
  appearance: none;
  background-color: #dce1e9;
  margin: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.radio-control + .radio-control {
  margin-top: 1em;
}

input[type="radio"]::before {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 14px 14px #3268b2;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}
