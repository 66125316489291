.parking-footer-button{
  border-radius: 12px;
  height: 48px;
  background-color: rgba(62, 201, 142, 0.30);
  width: calc(70% + 34px);
  border: none;
  box-shadow: none;
}

.parking-footer-white {
  background-color: #FFF;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
  margin-left: 0;
  margin-right: 0;
  bottom: 0px;
  position: fixed;
  z-index: 1;
}

.swipe-arrows {
  position: absolute;
  bottom: 94px;
  opacity: 0;
  animation: rightArrowAnimate 5s ease-in-out infinite;
}

.text-overlay {
  position: relative;
  text-align: center;
}

@keyframes rightArrowAnimate {
  0% {
    transform: translateX(-50%);
  }
  25% {
    opacity: 0;
    transform: translateX(-50%);
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0;
    transform: translateX(50%);
  }
  100% {
    transform: translateX(50%);
  }
} 

@keyframes showHide {
  0% {width:100%}
  40% {width:0%}
  60% {width:0%;}
  100% {width:100%;}
}