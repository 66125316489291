.map-footer-white {
  width: 100%;
  min-height: 110px;
  background-color: white;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  bottom: 60px;
  position: fixed;
  z-index: 1;
  box-shadow: 0px -4px 16px rgba(43, 68, 105, 0.15);
}

.map-footer-blue {
  min-height: 50px;
  border-radius: 1.25rem 1.25rem 0rem 0rem;
  background: linear-gradient(180deg, #3268B2 0%, #295494 100%);
  box-shadow: 0px -2px 4px 0px rgba(35, 49, 68, 0.25);
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  bottom: 0;
  position: fixed;
  z-index: 2;
}

.map-header-blue {
  border-radius: 0rem 0rem 0.75rem 0.75rem;
  background: var(--menu-blue-3, linear-gradient(360deg, #2D5D9F 0%, #3268B2 100%));
  box-shadow: 0px 4px 12px 0px rgba(35, 49, 68, 0.25);
  min-height: 50px;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  top: 0;
  position: fixed;
  z-index: 3;
}

.header-blue {
  z-index: 3;
  top: 0;
  border-radius: 0rem 0rem 0.75rem 0.75rem;
  background: var(--menu-blue-3, linear-gradient(360deg, #2D5D9F 0%, #3268B2 100%));
  box-shadow: 0px 4px 12px 0px rgba(35, 49, 68, 0.25);
}


.map-header-blue-sparkle {
  border-radius: 0rem 0rem 0.75rem 0.75rem;
  background: radial-gradient(
    103.47% 87.01% at 92.82% 100%,
    #769ed1 0%,
    rgba(50, 104, 178, 0) 100%
  );
}

.map-footer-blue_ {
  min-height: 70px;
  background-color: white;
  margin-left: 0;
  margin-right: 0;
  max-width: none;
  bottom: 0;
  position: fixed;
  z-index: -1;
}

.map-search-input {
  display: flex;
  align-items: center;
  border-radius: 13px !important;
  height: 45px;
}

.box-fixed {
  background-color: #3a4d8f;
  // border-bottom-Right-Radius: 25px;
  // border-bottom-left-radius: 25px;
  border-radius: 4px;
  color: white;
  position: fixed;
  width: 100px;
  z-index: 1;
  top: 30px;
  right: 30px;
}

.price-tag {
  background-color: #4285f4;
  border-radius: 8px;
  color: #ffffff;
  font-size: 14px;
  padding: 10px 15px;
  position: relative;
}

.price-tag::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #4285f4;
}

.map-typog {
  position: absolute;
  line-height: 1;
  color: #fff;
  top: 1.2em;
  font-size: 11px !important;
}

.map-typog-selected {
  position: absolute;
  line-height: 1;
  color: #3a4d8f;
  top: 1.2em;
  font-weight: bold !important;
  font-size: 11px !important;
}

.map-root {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.map-icon-selected {
  color: white;
  font-size: 40px;
}

.map-icon {
  color: #3a4d8f;
  font-size: 40px;
}

.map-info-box {
  margin-top: -16px;
  margin-left: -16px;
}

.parking-char {
  text-align: center;
  margin: 0;
  color: #455d80;
  font-size: 40px !important;
  padding: 0;
  font-weight: bold !important;
}

.marker-l {
  margin-bottom: -20px;
}

.public-form {
  touch-action: none;
  min-height: calc(100vh - 65px);
  background-color: #F5F6FA;
  width: 100vw;
}

.font16 input {
  font-size: 16px !important;
}

.autocomplete-search-lots div div {
  padding: 6px 2px 6px 6px !important;
}

.input-lots {
  margin-bottom: 10px;
}

.input-lots div fieldset {
  border: none;
}

.input-lots input {
  border-radius: 20px !important;
  font-size: 16px !important;
  color: #2B4469;
  font-family: Roboto;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.map-search-lots {
  border-radius: 12px;
  height: 48px;
  background-color: #F5F6FA;
  width: 100%;
  color: rgba(35, 49, 68, 0.5);
  border: none;
  box-shadow: none;
  border: 1px solid #ECEFF2;

  &:hover {
    background-color: #F5F6FA;
  }
}

.btn-gps-navigate {
  position: absolute;
  top: -2rem;
  right: -0.25rem;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 8px rgba(43, 68, 105, 0.35);
  width: 40px;
  height: 40px;
  z-index: 1050;
}

.secondary-title-text {
  color: #fff;
  font-size: 1.125rem;
  font-family: 'Roboto-Semi-Bold';
  font-weight: 500;
  letter-spacing: 0.05625rem;
}

.primary-body-text {
  color: #2B4469;
  font-family: 'Roboto-Semi-Bold';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
}

.text-hyperlink {
  cursor: pointer;
  color: #3268B2;
  text-align: right;
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; 
}

.paper-shadow {
  border-radius: 1rem;
  filter: drop-shadow(0px 4px 8px rgba(96, 100, 112, 0.25));

  & > span:first-child > .MuiListItem-root {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  & > span:last-child > .MuiListItem-root {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
}

.license-plate-on-map {
  position: absolute;
  top: -60px;
  left: 1rem;
  z-index: 1050;
  text-align: center;
}

.search-menu {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 1050;
}

.recent-searches {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  top: 150px;
  display: flex;
  justify-content: center;
  background-color: #fff;
  animation: slide-in 1s ease-out;
}

.recent-searches div {
  width: 100%;
}

.footer-empty-space {
  height: 5rem;
}

.hide-component {
  height: 0;
  overflow: hidden;
}

.google-maps-container {
  height: calc(100vh + 9rem);
  width: 100%; 
  position: absolute;
  top: -9rem;
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  25% {
    opacity: 0;
    transform: translateY(50px);
  }

  75% {
    transform: translateY(0px);
  }

  100% {
    opacity: 1;
  }
}