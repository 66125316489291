.title-text {
  color: #2B4469;
  font-size: 1.125rem;
  font-family: 'Roboto-Semi-Bold';
  font-weight: 500;
  letter-spacing: 0.05625rem;
}

.title-text-bold {
  color: #2B4469;
  font-size: 1.125rem;
}

.text-bold {
  color: #3268B2;
  font-size: 1.125rem;
}

.h4-text-light-secondary {
  color: #3268B2;
  font-family: 'Roboto';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.h4-text-neutral {
  color: rgba(35, 49, 68, 0.5);
  font-size: 0.85rem;
  font-style: normal;
  font-weight: 500;
}

.card-placeholder {
  background-color: #F5F6FA;
}

.card-shadow {
  box-shadow: 0px 2px 8px rgba(35, 49, 68, 0.10);
}

.selected-card {
  border: 2px solid #90ADD5;
}

.h4-default {
  font-weight: 500;
}

.h4-text {
  color: #2B4469;
  font-family: 'Roboto-Semi-Bold';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.h4-text-blue {
  color: #3268B2;
  font-family: 'Roboto-Semi-Bold';
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}

.h4-text-light {
  color: #2B4469;
  font-family: 'Roboto';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.h4-text-light-blue {
  color: #3268B2;
  font-family: 'Roboto-Semi-Bold';
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.h4-text-bold {
  color: #2B4469;
  font-family: 'Roboto-Semi-Bold';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.h4-text-bold-blue {
  color: #3268B2;
  font-family: 'Roboto-Semi-Bold';
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}

.h4-text-danger {
  color: #32B27C;
  font-weight: 500;
  white-space: 'nowrap';
  overflow: 'hidden';
  text-overflow: 'ellipsis';
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.h6-text {
  color: #2B4469;
  font-family: 'Roboto-Semi-Bold';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0.04375rem;
}

.body1-text {
  color: #2B4469;
  font-family: 'Roboto';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.body2-text {
  color: #2B4469;
  font-family: 'Roboto';
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.body2-hypertext {
  text-decoration: none;
  color: #2B4469;
  font-family: 'Roboto-Semi-Bold';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}

.text {
  color: #2B4469;
  font-family: 'Roboto-Semi-Bold';
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
}

.h5-text {
  color: #2B4469;
  font-family: 'Roboto-Semi-Bold';
  font-style: normal;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.5rem;
}

.double-letter-spacing {
  letter-spacing: 0.045rem;
}

.blue-text {
  color: #3268B2;
}

.transparent-blue-text {
  color: #3267b2c0;
}

.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}